<div class="cardContainer">
    <figure [ngClass]="courseData?.status == 'completed' ? 'completedHighlight' : ''">
        <div *ngIf="courseData?.status == 'completed' && !isCatalog" class="tag completedTag">{{'Completed'}}</div>
        <img [src]="courseData?.image?.url" alt="">
        <button *ngIf="userDetails" class="statusBtn" [ngClass]="courseData?.status == 'completed' ? 'playAgain' : 'start'" mat-flat-button (click)="enrollCourse()"><em class="icon-play"></em> {{ courseData?.status == 'start' ? "START" : (courseData?.status == 'completed' ? 'PLAY AGAIN' : 'RESUME' )}}</button>
        <button *ngIf="!userDetails" class="statusBtn" mat-flat-button (click) ="loginRedirect()" [ngClass]="'start'">
          <em class="icon-play"></em> {{"START"}}
        </button>
        <div class="duration">{{courseData?.contentDuration}}</div>
    </figure>
    <div class="coursedetails">
      <h3>{{isCatalog ? courseData?.areaName : courseData?.name}}</h3>
      <span class="gradient"></span>
      <div class="btnArea">
        <div class="courseInfo">
          <button (click)="courseDetail()"><div class="infoIcon">i</div>Course Details</button>
        </div>
        <circle-progress *ngIf="userDetails && courseData?.status != 'start'" [percent]="courseData?.course_percentage ? courseData?.course_percentage : '0'" [radius]="17" [space]="-3"
          [outerStrokeGradient]=false  [outerStrokeWidth]="3" [outerStrokeColor]='courseData?.course_percentage > 99 ? "#5CB646" : "#FFB74D"'
          [innerStrokeColor]= '"#F0F0F0"' [innerStrokeWidth]="3" [animation]="true" [animationDuration]="300" [showSubtitle]="false" [titleFontSize]="13" [clockwise]="false">
        </circle-progress>
      </div>
      <p *ngIf="isCatalog">{{courseData?.longDescription}}</p>
    </div>
  </div>
