import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, ActivatedRoute } from "@angular/router"
import { AppConfigService } from 'src/app/utils/app-config.service';
import { CatalogService } from "../../services/catalog.service"
import { environment } from '@env/environment';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { SlideIn } from '../../animations';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import * as AOS from 'aos';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Input } from "@angular/core";
@Component({
  selector: 'app-assessments-list',
  templateUrl: './assessments-list.component.html',
  styleUrls: ['./assessments-list.component.scss'],
  animations: SlideIn
})
export class AssessmentsListComponent implements OnInit {
  isActiveAll = true;
  mainTabSelected = 0;
  tabValues;
  pageNumber = 0;
  selectedTab;
  @Input('fromTab') fromTab;
  firstTabValue = { 'name': 'All Courses', 'cid': 'All' }
  isviewMore = true;
  areaCards = [];
  // catlogData:any = [];
  noDataFound;
  noDataSkeleton;
  skletonFiller: any = [].fill(8)
  blobToken: string = environment.blobKey;
  public destroyed = new Subject<any>();
  subscriberdata: any;
  productType: string = 'course';
  filteredTab: string = 'all';
  sliceDigits: number = 6;
  userDetails: any;
  searchKey: string = '';
  enroledCourse: any = [];
  secretKey = "(!@#Passcode!@#)";
  tabName: string = 'All Courses';
  pageSize: number = 6;
  courseCount: number;
  myCourse: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchInput = new Subject<string>();
  toPage: string;
  constructor(private _loading: LoadingService,
    private router: Router,
    private catalogService: CatalogService,
    private route: ActivatedRoute,
    private appConfig: AppConfigService,
    public toast: ToastrService,
    private util: UtilityService,
    private appconfig: AppConfigService,
  ) {
    // let CPage = this.appConfig.getLocalStorage('currentPage')
    // this.pageNumber = CPage ? parseInt(CPage) : 0;
  }

  ngOnInit() {
    AOS.init();
    this.userDetails = JSON.parse(this.appConfig.getLocalStorage('userDetails'));
    if (this.route.snapshot.queryParams.selectedTab) {
      this.fromTab = atob(this.route.snapshot.queryParams.selectedTab);
    }
    this.route.queryParams.subscribe((params) => {
      this.pageNumber = params?.pgNum ? parseInt(params?.pgNum) : 0;
      this.toPage = params?.toPage ? params?.toPage : '';
    })
    this.getDomain();
    this.subscriberdata = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.fromTab = atob(this.route.snapshot.queryParams.selectedTab);
      this.getDomain();
    })
    this.catalogSearch();
  }
  getDomain() {
    const apiParms = {
      productType: this.productType,
      courseOrigin: environment.userOrigin
    }
    this.catalogService.getCatalog(apiParms).subscribe((response: any) => {
      if (response.data.length > 0) {
        this.tabValues = response.data;
        this.tabValues.sort((a, b) => a.sequenceOrder > b.sequenceOrder ? 1 : -1);
        this.tabValues.splice(0, 0, this.firstTabValue);
        if (this.fromTab != 'All') {
          const currentDomain = this.tabValues.find(e => e.cid == this.fromTab);
          this.tabName = currentDomain.name;
        }
        let selectedDetails: any = JSON.parse(this.appConfig.getLocalStorage('selectedCategory'));
        if (selectedDetails) {
          this.toPage === "courseDetail" ? this.pageChange(selectedDetails) : this.tabChange(selectedDetails);
        } else {
          this.getCourseByDomain();
        }
      } else {
        this.tabValues = []
      }
    })
  }
  getCourseByDomain() {
    this.noDataSkeleton = true;
    const params = {
      "domainId": this.fromTab == 'All' ? 'all' : this.fromTab,
      "size": this.pageSize,
      "pagenumber": this.pageNumber,
      "records": "",
      "search": this.searchKey.trim(),
      "courseOrigin": environment.userOrigin,
    }
    this.catalogService.getCourseByDomain(params).subscribe((result: any) => {
      if (result?.success && result?.data.length) {
        this.areaCards = result.data;
        this.courseCount = result.totalCount;
        setTimeout(() => {
          this.paginator.length = this.courseCount;
        }, 500);
        if (this.userDetails) {
          this.setpercentage();
        } else {
          this.noDataSkeleton = false;
        }
      } else {
        setTimeout(() => {
          this.areaCards = [];
          this.courseCount = 0;
          this.paginator.length = this.courseCount;
          this.noDataSkeleton = false;
        }, 500);
      }
    });
    this._loading.setLoading(false, environment.API_BASE_URL + "getCourseByDomain");
  }
  setpercentage() {
    const apiparam = {
      userId: this.userDetails.userId,
    }
    this.catalogService.getmyLearning(apiparam).subscribe((result: any) => {
      if (result?.success && result?.data.length) {
        this.myCourse = result?.data;
        this.areaCards.forEach((element: any) => {
          let flag = this.myCourse.find(e => e.course_id == element.competencyId);
          if (flag) {
            element.status = flag.status;
            element.course_percentage = flag.course_percentage;
            element.enrolledCourse = true;
          } else {
            element.status = 'start';
            element.course_percentage = '0';
          }
        });
      } else {
        this.areaCards.forEach((element: any) => {
          element.status = 'start';
          element.course_percentage = '0';
        });
      }
      this.noDataSkeleton = false;
    });
    this._loading.setLoading(false, environment.API_BASE_URL + "myLearning");
  }
  catalogSearch() {
    this.searchInput.pipe(debounceTime(400), distinctUntilChanged()).subscribe(value => {
      if (value.trim().length > 3 || value.trim().length == 0) {
        this.pageNumber = 0;
        this.paginator.pageIndex = 0;
        this.paginator.firstPage();
        this.getCourseByDomain();
      }
    })
  }
  paginate(e: PageEvent) {
    this.pageNumber = e.pageIndex;
    this.getCourseByDomain();
    // this.appConfig.setLocalStorage('currentPage', this.pageNumber);
    // let element = document.getElementById("explore");
    // element.scrollIntoView({ behavior: 'smooth' });
  }
  tabChange(data) {
    this.fromTab = data.cid;
    this.tabName = data.name;
    this.searchKey = '';
    this.pageNumber = 0;
    this.paginator.pageIndex = 0;
    this.paginator.firstPage();
    this.getCourseByDomain();
    // let learn = this.appConfig.getLocalStorage('myLearning');
    // if (!learn) {
    //   let element = document.getElementById("explore");
    //   element.scrollIntoView({ behavior: 'smooth' });
    // }
    this.appconfig.setLocalStorage('selectedCategory', JSON.stringify(data));
  }
  pageChange(data) {
    this.fromTab = data.cid;
    this.tabName = data.name;
    this.searchKey = '';
    this.paginator.pageIndex = this.pageNumber;
    this.toPage = '';
    this.getCourseByDomain();
  }
  ngOnDestroy() {
    this.subscriberdata.unsubscribe();
  }
}
