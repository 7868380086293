<div *ngIf="loading" class="loading-container flex-content-center">
  <img style="width:150px; " src="https://lmsassetspremium.lntedutech.com/portalicons/loader.gif" />
</div>
<!--The content below is only a placeholder and can be replaced.-->
  <div *ngIf="isMobile" style="text-align: center; position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);border: 3px solid #C02222 !important; padding: 10px;">
    This version of the application is being constructed for mobile device.<br> We will keep you notified. <br> <strong>Please use from desktop / laptop.</strong><br><br><span style="font-size: 6px;">--Your browser running on {{platformTxt}} --</span>
  </div>
<div class="body_area"  *ngIf="!isMobile">
  <app-header></app-header>
  <div class="contentArea">
    <router-outlet></router-outlet>
  </div>
  <ng-container *ngIf="!hideFooter">
    <app-footer></app-footer>
  </ng-container>
</div>
