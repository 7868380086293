<!-- <section class="relatedProgramsAssessments programList">
    <div class="container">
        <div class="commonHeadingStyle">Related Courses</div>
    </div>
    <div class="programBg commonCSS">
        <div class="container">
            <div class="wrapper">
                <owl-carousel-o [options]="relatedList">
                    <ng-container>
                        <ng-template carouselSlide *ngFor="let relatedchild of [1,2,3,4,5,6]">
                            <div class="relatedList">
                                <span class="heading">{{'Node.js'}}</span>
                                <figure>
                                    <img  [src]="'https://lmsassetspremium.lntedutech.com/portalicons/Orthographic projection.webp'" alt="" />
                                </figure>
                                <div class="contentWrapper">
                                    <span class="batchStarts"><em class="icon-Time"></em> Batch starts on : {{'20-3-2023'}}</span>
                                    <div class="mnthStarts">
                                        <span class="months"><em class="icon-Booked"></em> {{'24 HRS'}}</span>
                                        <span class="stars">
                                            <em class="icon-Star" *ngFor="let rate of  [1,2,3,4]"></em>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section> -->

<section class="relatedSection" *ngIf="relatedData.length">
    <div class="container">
        <h3>Related Courses</h3>
        <div class="carouselWrapper">
            <owl-carousel-o [options]="relatedList">
                <ng-template carouselSlide *ngFor="let item of relatedData">
                    <div class="card" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                        <figure class="courseFigure">
                            <img class="courseImg" [src]="item?.image?.url" alt="">
                            <div class="play" (click)="enrollCourse(item)">
                                <em class="icon-play"></em>
                            </div>
                            <div class="relatedduration">{{item?.contentDuration}}</div>
                        </figure>
                        <div class="content">
                            <div class="instructor">
                                <div class="imageWrapper">
                                    <figure class="instructorFigure" *ngFor="let instructor of item?.trainingDetails">
                                        <img [src]="instructor?.image" alt="">
                                    </figure>
                                </div>
                                <span>{{item?.trainingDetails[0]?.name}} {{item?.trainingDetails.length > 1 ? item?.trainingDetails.length - 1 : ''}}</span>
                            </div>
                            <h3 class="courseTitle">{{item?.areaName}}</h3>
                            <p class="itemDescription">{{item?.shortDescription}}</p>
                            <button class="courseInfo" (click)="courseDetail(item)"><div class="infoIcon">i</div>Course Details</button>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<!-- <section class="relatedProgramsAssessments assessmentList" *ngIf="item?.subHeading2?.dispalystatus">
    <div class="container">
        <div class="commonHeadingStyle">{{item?.subHeading2?.title}}</div>
    </div>
    <div class="assessmentBg commonCSS">
        <div class="container">
            <div class="wrapper">
                <owl-carousel-o [options]="relatedList">
                    <ng-container>
                        <ng-template carouselSlide *ngFor="let relatedcourse of item?.subHeading2?.innerArray">
                            <div class="relatedList">
                                <span class="heading">{{relatedcourse?.title}}</span>
                                <figure>
                                    <img [src]="relatedcourse?.imageurl" alt="" />
                                </figure>
                                <div class="contentWrapper">
                                    <div class="mnthStarts">
                                        <span class="stars">
                                            <em class="icon-Star" *ngFor="let rate of relatedcourse?.rating"></em>
                                        </span>
                                    </div>
                                    <div class="level">
                                        <div>
                                            <p>Skill Level</p>
                                            <span><em class="icon-Level2"></em> {{relatedcourse?.level}}</span>
                                        </div>
                                        <div>
                                            <p>{{'No. of Assessments '}}</p>
                                            <span><em class="icon-Document"></em> {{relatedcourse?.NoOfAssessment}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section> -->
