import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from '@env/environment';
import { APP_CONSTANTS } from "src/app/utils/app-constants.service";
import { AppConfigService } from 'src/app/utils/app-config.service';
import { UtilityService } from "src/app/services/utility.service";
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss']
})
export class HeaderImageComponent implements OnInit {
  @Input('HomeBannerImage') HomeBannerImage: any;
  secretKey = "(!@#Passcode!@#)";
  blobToken: string = environment.blobKey;
  showAvatar: boolean;
  userDetails: any;
  dynamicData: any
  owlCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    dots: true,
    navSpeed: 4000,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1
      }
    }
  }
  constructor(private appConfig: AppConfigService, private router: Router, private util: UtilityService, private commonService: CommonService) { }

  ngOnInit() {
    this.rxjsHeaderAvatarUpdate();
    // this.getSliderContent()
  }

  navigateCatalog() {
    this.router.navigate(['/myLearning']);
    // this.appConfig.routeNavigationWithQueryParam(APP_CONSTANTS.ENDPOINTS.catalog.home, { fromPage: btoa("viewAll"), selectedTab: btoa('All') ,productType : btoa('all')});
    // const catalogsection = document.getElementById('catalogsection').scrollIntoView({behavior:'smooth'});
  }

  getSliderContent() {
    this.commonService.getCertificationDetails().subscribe((response: any) => {
      if (response.success) {
        this.dynamicData = response.data
      }
    })
  }

  rxjsHeaderAvatarUpdate() {
    if (this.appConfig.getLocalStorage('token')) {
      this.showAvatar = true;
      this.userDetails = JSON.parse(this.appConfig.getLocalStorage('userDetails'));
    }
    this.util.headerSubject.subscribe((data: any) => {
      this.showAvatar = data;
      this.userDetails = JSON.parse(this.appConfig.getLocalStorage('userDetails'));
    });
  }

  gotoSkillOmeter() {
    if (this.userDetails) {
      this.appConfig.routeNavigation('skillOmeter');
    } else {
      this.appConfig.routeNavigationWithQueryParam(APP_CONSTANTS.ENDPOINTS.onBoard.login, { fromPage: this.commonService.encrypt('0', this.secretKey) });
    }
  }
}
