import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { environment } from '@env/environment';
import { AppConfigService } from '../utils/app-config.service';
import { UtilityService } from './utility.service';
import { APP_CONSTANTS } from '../utils/app-constants.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { SocketService } from './socket.service';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseurl = environment.API_BASE_URL;
  currentPageNumber: number = 0;
  secretKey = "(!@#Passcode!@#)";
  constructor(    private cookieService: CookieService ,private http: HttpClient, private appconfig: AppConfigService,
    public toast: ToastrService, private util: UtilityService, private socketService: SocketService) {
      this.util.pageNumberSubject.subscribe((value:number)=> {
        this.currentPageNumber = value;
      })
    }

  // tslint:disable-next-line: typedef
  signup(signupData) {
    return this.http.post(this.baseurl + 'userRegistration', signupData);
  }
  // tslint:disable-next-line: typedef
  login(loginData) {
    return this.http.post(this.baseurl + 'userLogin', loginData);
  }
  fogetPasswordEmail(mailData) {
    return this.http.post(this.baseurl + 'userforgotPassword', mailData);
  }
  resetPassword(pwdData) {
    return this.http.post(this.baseurl + 'submitResetPassword', pwdData);
  }

  //Course player  start
  getTOC(postParam) {
    return this.http.post(environment.apiUrl + 'navTreeV2', postParam);
  }
  //
  skillExchangeLaunch(data) {
    return this.http.post(this.baseurl + 'skillExchangeLaunch', data);
  } 
  urlStatusCheck(url) {
    return fetch(url, {mode: "no-cors"})
    .then(res => true)
    .catch(err => false)
  }

  checkLogout() {
    const userDetail = JSON.parse(localStorage.getItem('userDetails')) || null;
    if ((userDetail != null && userDetail !== undefined)) {
      return userDetail;
    } else {
     this.logout();
    }
  }

  // Logout
  logout(value?) {
    // SOCKET DISCONNECTION START
    if (this.socketService?.socket?.connected) {
      this.socketService.Connectsocket({ type: 'disconnect' }).subscribe(quote => {
      });
      this.socketService.closeSocket();
    }
    this.cookieService.delete('isLoggedIn')
    this.cookieService.delete('isLoggedInFunc')
    this.appconfig.clearSessionStorage();
    this.appconfig.clearLocalStorage();
    this.http.post(this.baseurl + 'logout', {});
    this.util.headerSubject.next(false);
    this.util.cartSubject.next(false);
    this.util.showkycProgress.next(false);
    this.util.footerSubject.next(false);
    this.appconfig.routeNavigationWithQueryParam(APP_CONSTANTS.ENDPOINTS.onBoard.login, { fromPage: this.encrypt('0', this.secretKey) });
    //this.toast.success("You've successfully logged out");
  }
  // Validate captcha token
  validateToken(secret: string, token: string) {
    return (_: AbstractControl) => {
      return this.http.get(`https://www.google.com/recaptcha/api/siteverify?secret=${secret}&response=${token}`).pipe(map((res: any) => {
        if (res.success) {
          return null;
        }
        return { tokenInvalid: true };
      }));
    }
  }

  //Static Page - Home
  getStaticDataHome(params) {
    return this.http.post(this.baseurl + 'getdynamicPage1',params);
  }

  //footer
  getFooter(params) {
    return this.http.post(this.baseurl + 'getdynamicPageFooter',params);
  }

  // certification details
  getCertificationDetails() {
    return this.http.get(this.baseurl + 'getdynamicPage2');
  }

  profileUpdate(profileData){

    return this.http.post(this.baseurl + 'updateProfile', profileData);
  }

  //UpdateImage
  getprofileImgUpdate(formData) {
    return this.http.post(this.baseurl + 'profileImgUpload', formData);
  }

  getProfile(params){

    var value = {
      'userId': params
    }
    return this.http.post(this.baseurl + 'getUserInfoById',value);
  }
  // code added
  getmyAssesments(param){

    return this.http.post(this.baseurl + 'getmyAssesments',param);
  }
  deactivateAccount(param){

    return this.http.post(this.baseurl + 'deactivateAccount',param);
  }
  updatePassword(param){

    return this.http.post(this.baseurl + 'updatePassword',param);
  }
  //skill profile
  getPortfolioDetails(param){

    return this.http.post(this.baseurl + 'getPortfolioDetails',param);
  }
  verifySsotoken(param){

    return this.http.get(environment.sso + '/simplesso/verifytoken?ssoToken='+param);
  }
  getSsotoken(param){

    return this.http.get(environment.sso + '/simplesso/login?serviceURL='+param);
  }
  //skillometer
  getjobDetails(){

    return this.http.get(this.baseurl + 'getjobDetails');
  }
  getroleDetails(){

    return this.http.get(this.baseurl + 'getroleDetails ');
  }
  getskillDetails​(param){

    return this.http.post(this.baseurl + 'getskillDetails​',param);
  }//"userId": "go7m52"
  gototest(param){

    return this.http.post(this.baseurl + 'gototest',param);
  }
  postKycUserDetails(kycData){
    return this.http.post(this.baseurl + 'addUserDetails',kycData)
  }
  getKycUserDetails(userId){
    var value = {
      'userId': userId
    }
    return this.http.post(this.baseurl + 'getProfileDetailsById',value)
  }
  getProfilePercentage(data){
    return this.http.post(this.baseurl + 'profilePercentage',data)
  }
  submitUserProfile(data){
    return this.http.post(this.baseurl + 'submitUserProfile',data);
  }
  encrypt(data,encryptionKey) {
    try {
      return CryptoJS.AES.encrypt(data, encryptionKey.trim()).toString();
    } catch (e) {
      return data;
    }
  }

  getProfileCertificate(value){
    var data = {
      'userId': value
    }
    return this.http.post(this.baseurl + 'getUserCertificate',data);
  }

  getBadge(data) {
    return this.http.post(this.baseurl + 'getBadge', data)
  }
}

