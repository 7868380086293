import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CatalogService } from 'src/app/services/catalog.service';
import * as CryptoJS from 'crypto-js';
import { UtilityService } from 'src/app/services/utility.service';
import { AppConfigService } from 'src/app/utils/app-config.service';
import { APP_CONSTANTS } from 'src/app/utils/app-constants.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent implements OnInit {
  @Input() courseData: any;
  @Input() isCatalog: boolean;
  @Input() pageNum: number;
  secretKey = "(!@#Passcode!@#)";
  userDetails;
  enrolledCourse: any = [];
  @Input() isEnroll: any;
  @Input() learning: boolean;
  constructor(private route: Router, private toast: ToastrService, private catalogService: CatalogService, private util: UtilityService, private appConfig: AppConfigService, private commonService: CommonService) {
    this.userDetails = JSON.parse(this.appConfig.getLocalStorage('userDetails'));
  }

  ngOnInit(): void {
    // console.log(this.courseData)
  }

  openPlayer(c) {
    const detail = {
      "id": c?.id,
      "wishlist": c?.wishlist,
      "wishlist_id": c?.wishlist_id,
      "enrollment_status": c?.enrollment_status,
      "course_name": c?.course_name,
      "course_status": c?.course_status,
      "batch_id": undefined,
      "batchEndTime": c?.batchEndTime,
      "isTesting": c?.isTesting ? true : false,
      "link": c?.link,
      "toc": c?.toc,
      "lastModule": c?.lastModule,
      "lastTopic": c?.lastTopic,
      "checklevel": c?.checklevel,
      "module_id": c?.module_id,
      "topic_id": c?.topic_id,
      "course_type": c?.course_type,
      "payType": c?.batchid ? undefined : 'paid',
      "endCourseTimeStamp": c?.endCourseTimeStamp,
      "startCourseTimeStamp": c?.startCourseTimeStamp
      // "id": "77dd9f2f",
      // "wishlist": false,
      // "wishlist_id": null,
      // "enrollment_status": null,
      // "course_name": "extract upskill course for testing course %",
      // "course_status": "incomplete",
      // "batch_id": undefined,
      // "batchEndTime": null,
      // "isTesting": false,
      // "link": "/Ang_dev_3103/scormdriver/indexAPI.html",
      // "toc": "1",
      // "lastModule": "Angular Crash Course",
      // "lastTopic": "Angular Crash Course",
      // "checklevel": false,
      // "module_id": "115605",
      // "topic_id": "115605",
      // "course_type": "corporate",
      // "payType": "paid",
      // "endCourseTimeStamp": null,
      // "startCourseTimeStamp": "2022-12-31T04:28:07.028Z"
    }
    this.appConfig.setLocalStorage('coursePlayerData', JSON.stringify({ detail }));
    this.appConfig.routeNavigation(APP_CONSTANTS.ROUTES.coursePlayer);
  }

  enrollCourse() {
    if (this.userDetails) {
      const apiParam =
      {
        "enrolledCourse": true,
        "is_free": true,
        "username": this.userDetails?.email,
        "course_id": CryptoJS.AES.encrypt(this.isCatalog ? this.courseData?.competencyId : this.courseData.course_id, this.secretKey.trim()).toString(),
        "courseName": CryptoJS.AES.encrypt(this.isCatalog ? this.courseData?.areaName : this.courseData.name, this.secretKey.trim()).toString(),
        "firstName": CryptoJS.AES.encrypt(this.userDetails?.firstname, this.secretKey.trim()).toString(),
        "userId": this.userDetails?.userId
      }
      this.catalogService.userSyncUpLxp(apiParam).subscribe((result: any) => {
        if (result?.success) {
          this.util.isEnrolled.next(true);
          if (result.message != "Course already exists") {
            this.toast.success("You have been enrolled to the course successfully!");
          }
          this.openPlayer(result?.data);
        }
        else {
          this.toast.warning(result?.message ? result?.message : "Something Went Wrong!!");
        }
      });
    }
    else {
      this.appConfig.routeNavigationWithQueryParam(APP_CONSTANTS.ENDPOINTS.onBoard.login, { fromPage: this.commonService.encrypt('0', this.secretKey) });
    }
  }

  navigateToPlayer() {
    this.appConfig.routeNavigation(APP_CONSTANTS.ROUTES.coursePlayer);
  }
  courseDetail() {
    this.util.pageNumberSubject.next(this.pageNum);
    let routeParam = {
      cId: !this.isCatalog ? this.courseData.course_id : this.courseData.competencyId,
      aId: this.courseData.areaId,
      dId: !this.isCatalog ? this.courseData.parentId : this.courseData.domainId,
      st: 'start',
      courseName: this.courseData.courseURLName.replace(/\s+/g, '-')
    }
    if (this.userDetails && this.courseData?.status) {
      routeParam.st = this.courseData?.status;
    }
    this.appConfig.setLocalStorage('navToCourseDet', JSON.stringify(routeParam));
    let str = this.courseData.courseURLName.replace(/\s+/g, '-');
    this.appConfig.routeNavigationWithParam(APP_CONSTANTS.ENDPOINTS.coursedetails, str)
  }
  loginRedirect() {
    if (!this.userDetails) {
      const value = {
        name: this.courseData?.areaName,
        c_id: this.courseData?.competencyId
      }
      this.appConfig.setLocalStorage('enrollCourseDet', JSON.stringify(value));
    }
    this.appConfig.routeNavigationWithQueryParam(APP_CONSTANTS.ENDPOINTS.onBoard.login, { fromPage: this.commonService.encrypt('0', this.secretKey) });
  }
}
