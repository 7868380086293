<footer *ngIf="footerData">
  <section class="footerWrapper" *ngIf="showFooterContent">
      <div class="footer d-flex">
        <div class="logo">
          <figure>
            <img [src]="footerData?.logo" alt="" />
          </figure>
        </div>
        <nav>
          <a (click)="scollHandler(item.id)" *ngFor="let item of footerData?.content">{{item?.title}}</a>
        </nav>
        <div class="socialMedia">
          <span (click)="openSocialMedia(item)" *ngFor="let item of footerData?.socialMedia" [class]="item.title"><em [class]="item?.icon"></em></span>
        </div>
      </div>
  </section>
  <section class="copyRights">
    <div>
      <p>{{footerData?.copyRights}}</p>
    </div>
  </section>
</footer>
<!-- <div class="copyrightsTerms">
    <div>
        <span class="forSupport">For any Support:</span>
        <ul>
            <li><a href="noMailForYou@spambot.com" rel="nofollow" onclick="this.href='mailto:' + 'contact' + '@' + 'lntedutech.com'"><em class="icon-Email"></em> <span>{{footerData?.contact?.email}}</span></a></li>
            <li><em class="icon-Contact"></em> <span>{{footerData?.contact?.phone}}</span></li>
        </ul>
    </div>
    <p>&copy; {{footerData.copyRights}}</p>
</div> -->
