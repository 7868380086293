<header #header_width [class.sticky] ="sticky" data-aos="fade-down" data-aos-duration="1000">
  <!-- <div class="outline">

    </div> -->
  <!-- <div class="container"> -->
    <div class="leftWrapper">
      <div class="humburger">
        <div (click)="mobileMenu()" [class.close]="mobileshowMenu == 'in'">
          <div></div>
        </div>
      </div>
      <figure class="edutechLogo" (click)="homepage()">
        <img class="nonstickyimg" src="https://lmsassetspremium.lntedutech.com/portalicons/micro-learning-head.webp" alt="L&T Logo | MicroLearning">
        <img class="stickyimg" src="https://lmsassetspremium.lntedutech.com/portalicons/micro-learning-home.webp" alt="L&T Logo | MicroLearning" />
      </figure>
    </div>
    <div class="rightWrapper">
      <div class="signInRegister logoFix">
        <span class="learning" (click)="mylearning()" *ngIf="showAvatar && ispurchased">My Learning</span>
        <span class="allCourses" (click)="mylearning()">All Courses</span>
        <div *ngIf="showAvatar" [matMenuTriggerFor]="menu" [matTooltipPosition]="'left'" [matTooltip]="userDetails?.firstname + ' ' + userDetails?.lastname" class="avatar">
          <!-- <span class="iconImg" *ngIf="!profImage"><em class="icon-User"></em></span> -->
          <ngx-avatar *ngIf="!profImage" [name]="userDetails?.firstname + ' ' + userDetails?.lastname" [width]="20" [height]="20" [circular]="true" [size]="40" fontFamily="Roboto"></ngx-avatar>
          <figure *ngIf="profImage" class="profImg">
            <img [src]="profImage + blobToken" alt="" />
          </figure>
          <mat-menu #menu="matMenu" class="profileMenu">
            <div class="profile">
              <div class="profIcon">
                <figure class="profImg">
                  <!-- <span class="avatar" *ngIf="!profImage"><em class="icon-User"></em></span> -->
                  <ngx-avatar *ngIf="!profImage" [name]="userDetails?.firstname + ' ' + userDetails?.lastname" [width]="20" [height]="20" [size]="40" [circular]="true" fontFamily="Roboto"></ngx-avatar>
                  <img *ngIf="profImage" [src]="profImage + blobToken" alt="" />
                </figure>
              </div>
              <div class="profDesc">
                <strong>{{userDetails?.firstname + ' ' + userDetails?.lastname}}</strong>
                <span>{{userDetails?.emailId}}</span>
                <button *ngIf="userDetails?.userOrigin != 'skillExchange'" (click)="editProfile()">Edit Profile</button>
              </div>
            </div>
            <div class="settings">
              <!-- <span class="heading">Account</span> -->
              <div *ngIf="userDetails?.userOrigin != 'skillExchange'"><a (click)="navigateProfile('account')">Account settings</a></div>
              <div><a (click)="navigateProfile('badge')">My Badges</a></div>
              <!-- <div><a (click)="navigateProfile('history')">Purchase history</a></div>
              <div><a (click)="navigateProfile('payment')">Payment methods</a></div> -->
            </div>
            <div class="signout">
              <a class="signOut" (click)="logout()">Sign out</a>
            </div>
          </mat-menu>
        </div>
        <span class="login" (click)="openOnBoard('0')" *ngIf="!showAvatar">Sign in</span>
        <!-- <ng-container *ngIf="showAvatar">
          <button class="coursebtn" (click)="gotoCourse()" *ngIf="ispurchased">My Courses</button>
          <div [matMenuTriggerFor]="menu" [matTooltipPosition]="'left'" [matTooltip]="userDetails?.firstname + ' ' + userDetails?.lastname" class="avatar">
            <span *ngIf="!profImage"><em class="icon-User"></em></span>
            <figure *ngIf="profImage" class="profImg">
              <img [src]="profImage + blobToken" alt="" />
            </figure>
            <mat-menu #menu="matMenu" class="profileMenu">
              <div class="profile">
                <div class="profIcon">
                  <figure class="profImg">
                    <span class="avatar" *ngIf="!profImage"><em class="icon-User"></em></span>
                    <img *ngIf="profImage" [src]="profImage + blobToken" alt="" />
                  </figure>
                </div>
                <div class="profDesc">
                  <strong>{{userDetails?.firstname + ' ' + userDetails?.lastname}}</strong>
                  <span>{{userDetails?.emailId}}</span>
                  <button (click)="editProfile()">Edit Profile</button>
                </div>
              </div>
              <div class="settings">
                <span class="heading">Account</span>
                <div><a (click)="navigateProfile('account')">Account settings</a></div>
                <div><a (click)="navigateProfile('badge')">My Badges</a></div>
                <div><a (click)="navigateProfile('history')">Purchase history</a></div>
                <div><a  (click)="navigateProfile('payment')">Payment methods</a></div>
              </div>
              <div class="signout">
                <a class="signOut" (click)="logout()">Sign out</a>
              </div>
            </mat-menu>
          </div>
        </ng-container> -->
        <!-- <ng-container *ngIf="!showAvatar">
          <button class="login" (click)="openOnBoard('0')">Sign In</button>
          <button class="register" (click)="openOnBoard('1')">Register</button>
        </ng-container> -->
        <div class="aictelogo">
          <figure>
            <img src="https://lmsassetspremium.lntedutech.com/portalicons/aicte.webp" alt="">
          </figure>
        </div>
      </div>
    </div>
  <!-- </div> -->
  <div class="mobileMenu" [@mobileslideInOut]="mobileshowMenu">
    <div *ngIf="mobileshowMenu == 'in'">
      <span class="learning" (click)="mylearning()" *ngIf="showAvatar && ispurchased">My Learning</span>
      <span class="allCourses" (click)="mylearning()">All Courses</span>
      <span class="login" (click)="openOnBoard('0')" *ngIf="!showAvatar">Log in</span>
    </div>
  </div>
</header>

<mat-menu class="menuCustomClass" #menu="matMenu">
  <button mat-menu-item>Check My Score</button>
  <button mat-menu-item>Download My Certificate</button>
  <button mat-menu-item (click)="getFAQ()">FAQ</button>
</mat-menu>
