<ng-container>
  <div class="sucess-kyc-container">
    <img src="https://lmsassetspremium.lntedutech.com/portalicons/thankyou.webp" width="60px"/>
     <div class="kyc-txt">Thank you for choosing the assessment.</div>
     <div class="kyc-sub-txt">Please complete your KYC to proceed.</div>
     <div class="kyc-redirect">
          <span class="kyc-redirect-txt" (click)="selfClose()">I'm not sure yet</span>
          <button class="kyc-btn" (click)="navigateProfile()">Edit Profile</button>
     </div>
  </div>
</ng-container>
