import { Component, OnInit, HostListener, ChangeDetectorRef } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { UtilityService } from "src/app/services/utility.service";
import { AppConfigService } from "src/app/utils/app-config.service";
import { APP_CONSTANTS } from "src/app/utils/app-constants.service";
import { CatalogService } from "../../services/catalog.service"
import { environment } from '@env/environment';
import { Location } from '@angular/common';
import { SlideInOutAnimation } from '../../animations'
import Swal from 'sweetalert2';
import { LoadingService } from 'src/app/services/loading.service';
import {
  trigger, state, style, transition,
  animate, group
} from '@angular/animations';
import { SocketService } from "src/app/services/socket.service";
import { Subscription } from "rxjs";
import * as AOS from 'aos';
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [SlideInOutAnimation,
    trigger('mobileslideInOut', [
      state('in', style({
        'height': '100vh'
      })),
      state('out', style({
        'height': '0px'
      })),
      transition('in => out', [group([
        animate('300ms ease-in-out', style({
          'height': '0px'
        })),
      ]
      )]),
      transition('out => in', [group([
        animate('600ms ease-in-out', style({
          'height': '100vh'
        }))
      ]
      )])
    ]),]
})

export class HeaderComponent implements OnInit {
  blobToken: string = environment.blobKey;
  hoveron: boolean;
  isShowMenu: boolean = false;
  showMenu = 'out';
  mobileshowMenu = 'out';
  registerMenu = 'out';
  assessmentsList: boolean = false;
  coursesList: boolean = false;
  menu_tab: boolean = false;
  catalogMenu = [];
  showAvatar: boolean;
  userDetails: any;
  cartCount = 0;
  firstTabValue = { 'name': 'All', 'cid': 'All' }
  selectedTab;
  profImage: any;
  isCertified = false;
  isAssement = false;
  isCourse = false;
  isCredentials = false;
  offsetFlag = true;
  sticky: boolean = false;
  isHomePage: boolean = true;
  productType: string = 'assessment';
  l1: any = [];
  l2: any = [];
  l3: any = [];
  l4: any = [];
  l3item;
  l1name = "";
  l2name = "";
  l3name = "";
  longdesc = "";
  l1image = "";
  megaMenuL1Data: any = [];
  megaMenuL2Data: any = [];
  ispurchased: boolean = false;
  secretKey = "(!@#Passcode!@#)";
  private routerSubscription: Subscription;
  constructor(
    private appConfig: AppConfigService,
    private catalogService: CatalogService,
    private util: UtilityService,
    private commonservice: CommonService,
    private router: Router,
    private location: Location,
    private _loading: LoadingService,
    private socketService: SocketService,
    private cd: ChangeDetectorRef
  ) {
    this.profImage = this.appConfig.getLocalStorage('profileImage');
    this.routerSubscription = this.router.events.subscribe((event: any) => {
      // if (event instanceof NavigationStart) {
      //   console.log(event, 'nav started')
      // }
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/Home') || event.urlAfterRedirects.includes('/Home')) {
          this.sticky = false;
          this.isHomePage = true;
        } else {
          this.sticky = true;
          this.isHomePage = false;
        }
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll(e) {
    if (window.pageYOffset >= 10) {
      this.showMenu = 'out';
      this.mobileshowMenu = 'out';
      this.assessmentsList = false;
      this.coursesList = false;
    }
    if (this.isHomePage) {
      const feauturedSection: any = document.getElementById('section2');
      const featuredOffsetTop = feauturedSection?.offsetTop ? feauturedSection?.offsetTop : 649;
      const stick = window.pageYOffset >= (featuredOffsetTop - 75) ? true : false;
      if (stick != this.sticky) {
        this.sticky = stick;
        this.cd.detectChanges();
      }
    }
  }

  ngOnInit() {
    AOS.init();
    this.rxjsHeaderAvatarUpdate();
    this.util.isEnrolled.subscribe((result: boolean) => {
      this.ispurchased = result;
      this.cd.detectChanges();
    });
  }
  openOnBoard(value?) {
    this.appConfig.routeNavigationWithQueryParam(APP_CONSTANTS.ENDPOINTS.onBoard.login, { fromPage: this.commonservice.encrypt(value.toString(), this.secretKey) });
    this.mobileshowMenu = 'out';
  }
  rxjsHeaderAvatarUpdate() {
    if (this.appConfig.getLocalStorage('token')) {
      this.showAvatar = true;
      this.userDetails = JSON.parse(this.appConfig.getLocalStorage('userDetails'));
      this.getPurchasedCourse();
    }
    this.util.headerSubject.subscribe((data: any) => {
      this.showAvatar = data;
      this.userDetails = JSON.parse(this.appConfig.getLocalStorage('userDetails'));
      this.profImage = this.appConfig.getLocalStorage('profileImage');
      this.getPurchasedCourse();
    });
  }

  mobileMenu() {
    this.mobileshowMenu = this.mobileshowMenu == 'in' ? 'out' : 'in';
  }

  homepage() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Use 'smooth' for smooth scrolling, or 'auto' for instant scrolling
    });
    this.appConfig.routeNavigation(APP_CONSTANTS.ENDPOINTS.home);
  }

  editProfile() {
    this.appConfig.routeNavigation(APP_CONSTANTS.ENDPOINTS.userprofile);
  }

  getFAQ() {
    this.appConfig.routeNavigation(APP_CONSTANTS.ENDPOINTS.faq);
  }

  logout() {
    Swal.fire({
      customClass: {
        container: 'swalClass',
      },
      title: 'Are you sure you want to logout?',
      showCancelButton: true,
      confirmButtonColor: '#ffffff',
      cancelButtonColor: '#ffffff',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.commonservice.logout();
      }
    });
  }

  catalogHome(value, type) {
    this.productType = type;
    this.showMenu = 'out';
    this.mobileshowMenu = 'out';
    this.assessmentsList = false;
    this.coursesList = false;
    this.appConfig.routeNavigationWithQueryParam(APP_CONSTANTS.ENDPOINTS.catalog.home, { fromPage: btoa("viewAll"), selectedTab: btoa(value), productType: btoa(this.productType) });
  }

  navigateProfile(type) {
    this.router.navigate(['/userProfile'], { queryParams: { tabtype: type } })
  }
  mylearning() {
    this.appConfig.routeNavigationWithQueryParam(APP_CONSTANTS.ENDPOINTS.mylearning, { id: btoa('All') });
    this.mobileshowMenu = 'out';
  }
  gotoCourse() {
    var ValueData = JSON.parse(this.appConfig.getLocalStorage('valueData'));
    window.open(environment.lxp_url + "?queValue=" + encodeURIComponent(ValueData.queValue) + '&rpValue=' + encodeURIComponent(ValueData.rpValue) + '&dpValue=microsetportal', '_self');
  }
  getPurchasedCourse() {
    this.catalogService.getEnrolledCourse({ email: this.userDetails?.email }).subscribe((enrolledResult: any) => {
      if (enrolledResult?.success && enrolledResult?.data?.enrolledcourseIds.length > 0) {
        this.ispurchased = true;
      } else {
        this.ispurchased = false;
      }
      this.cd.detectChanges();
    })
  }
  skillLogout() {
    this.appConfig.clearLocalStorage();
    window.close();
  }

  ngOnDestroy() {
    this.appConfig.clearLocalStorageOne('myPurchase');
    this.routerSubscription.unsubscribe();
  }
}
