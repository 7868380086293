    <section class="testi">
            <div class="container">
                <div class="commonHeadingStyle">{{testimonal?.title}}</div>
                <div class="testimonialContent">
                    <owl-carousel-o [options]="testimonialOptions">
                            <ng-template carouselSlide *ngFor="let item of testimonal?.innerArray">
                                <div class="caption">
                                    <p class="text">{{item?.description}}</p>
                                    <figure>
                                        <img [src]="item?.imageurl" alt="" />
                                    </figure>
                                </div>
                                <div class="author">{{item?.name}}</div>
                                <div class="role">Progression</div>
                            </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </section>