import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})
export class GoalsComponent implements OnInit {
  @Input('AchieveGoal') AchieveGoal: any;

  constructor() { }

  ngOnInit(): void {
  }

}
