import { Component, OnInit } from "@angular/core";
import { CommonService } from 'src/app/services/common.service';
import * as CryptoJS from 'crypto-js';
import { environment } from '@env/environment';
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})

export class FooterComponent implements OnInit {
  footerData;
  secretKey = "(!@#Passcode!@#)";
  private routerSubscription: Subscription;
  showFooterContent: boolean = true;
  constructor(public commonService: CommonService, private router: Router) {
    this.routerSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/Home') || event.urlAfterRedirects.includes('/Home')) {
          this.showFooterContent = true;
        } else {
          this.showFooterContent = false;
        }
      }
    });
  }

  ngOnInit() {
    this.getFooterData()
  }

  getFooterData() {
    this.commonService.getFooter({ userOrigin: CryptoJS.AES.encrypt(environment.userOrigin, this.secretKey.trim()).toString() }).subscribe((response: any) => {
      if (response.success) {
        this.footerData = response.data.footerData;
      }
    })
  }
  openSocialMedia(item) {
    window.open(item.link, '_blank').focus();
  }
  scollHandler(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  }
  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
