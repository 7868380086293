import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';
import { CatalogService } from 'src/app/services/catalog.service';
import { environment } from '@env/environment';
import { LoadingService } from 'src/app/services/loading.service';
import { AppConfigService } from 'src/app/utils/app-config.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { APP_CONSTANTS } from 'src/app/utils/app-constants.service';
@Component({
  selector: 'app-relateditems',
  templateUrl: './relateditems.component.html',
  styleUrls: ['./relateditems.component.scss']
})
export class RelateditemsComponent implements OnInit {

  @Input('detailsdata') detailsdata: any;
  relatedList: OwlOptions = {
    autoplay: false,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    margin: 20,
    navSpeed: 700,
    navText: ["<i class='icon-arrow'></i>", "<i class='icon-arrow'></i>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  relatedData: any = [];
  userDetails: any;
  secretKey: string = "(!@#Passcode!@#)";
  constructor(private catalogService: CatalogService, private _loading: LoadingService, private appConfig: AppConfigService, private toast: ToastrService, private commonService: CommonService, private route: Router) {
    this.userDetails = JSON.parse(this.appConfig.getLocalStorage('userDetails'));
  }

  ngOnInit(): void {
    AOS.init();
    this.getCourseByDomain();
  }
  getCourseByDomain() {
    const apiParam = {
      "domainId": this.detailsdata?.domainId ? this.detailsdata.domainId : 'all',
      "size": 0,
      "pagenumber": 0,
      "records": "",
      "search": "",
      "courseOrigin": "microLearn"
    }
    this.catalogService.getCourseByDomain(apiParam).subscribe((result: any) => {
      if (result?.success && result?.data.length) {
        this.relatedData = result.data.filter(e => e.competencyId != this.detailsdata.cid);
        console.log(this.relatedData, 'relatedData')
      }
    });
    this._loading.setLoading(false, environment.API_BASE_URL + "getCourseByDomain");
  }
  enrollCourse(data) {
    if (this.userDetails) {
      const apiParam =
      {
        "enrolledCourse": true,
        "is_free": true,
        "username": this.userDetails?.email,
        "course_id": CryptoJS.AES.encrypt(data?.competencyId, this.secretKey.trim()).toString(),
        "courseName": CryptoJS.AES.encrypt(data?.areaName, this.secretKey.trim()).toString(),
        "firstName": CryptoJS.AES.encrypt(this.userDetails?.firstname, this.secretKey.trim()).toString(),
        "userId": this.userDetails?.userId
      }
      this.catalogService.userSyncUpLxp(apiParam).subscribe((result: any) => {
        if (result?.success) {
          if (result.message != "Course already exists") {
            this.toast.success("You have been enrolled to the course successfully!");
          }
          this.openPlayer(result?.data);
        }
        else {
          this.toast.warning(result?.message ? result?.message : "Something Went Wrong!!");
        }
      });
    }
    else {
      this.appConfig.routeNavigationWithQueryParam(APP_CONSTANTS.ENDPOINTS.onBoard.login, { fromPage: this.commonService.encrypt('0', this.secretKey) });
    }
  }
  openPlayer(c) {
    const detail = {
      "id": c?.id,
      "wishlist": c?.wishlist,
      "wishlist_id": c?.wishlist_id,
      "enrollment_status": c?.enrollment_status,
      "course_name": c?.course_name,
      "course_status": c?.course_status,
      "batch_id": undefined,
      "batchEndTime": c?.batchEndTime,
      "isTesting": c?.isTesting ? true : false,
      "link": c?.link,
      "toc": c?.toc,
      "lastModule": c?.lastModule,
      "lastTopic": c?.lastTopic,
      "checklevel": c?.checklevel,
      "module_id": c?.module_id,
      "topic_id": c?.topic_id,
      "course_type": c?.course_type,
      "payType": c?.batchid ? undefined : 'paid',
      "endCourseTimeStamp": c?.endCourseTimeStamp,
      "startCourseTimeStamp": c?.startCourseTimeStamp
    }
    this.appConfig.setLocalStorage('coursePlayerData', JSON.stringify({ detail }));
    this.appConfig.routeNavigation(APP_CONSTANTS.ROUTES.coursePlayer);
  }
  courseDetail(item) {
    // console.log(item, 'item')
    let routeParam = {
      cId: item.competencyId,
      aId: item.areaId,
      dId: item.domainId,
      st: 'start',
      courseName: item.courseURLName.replace(/\s+/g, '-')
    }
    if (this.userDetails && item?.status) {
      routeParam.st = item?.status;
    }
    this.appConfig.setLocalStorage('navToCourseDet', JSON.stringify(routeParam));
    let str = item.courseURLName.replace(/\s+/g, '-');
    this.appConfig.routeNavigationWithParam(APP_CONSTANTS.ENDPOINTS.coursedetails, str)
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    // console.log('same page route')
  }
}
