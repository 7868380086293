<section class="assessmentSection" id="explore">
    <div class="container">
            <div class="search">
                <mat-form-field appearance="outline" data-aos="fade-left" data-aos-duration="1000">
                    <input matInput type="text" placeholder="Search by Courses" [(ngModel)]="searchKey" autocomplete="off" (ngModelChange)="searchInput.next($event)">
                    <span matPrefix class="searchKey"><em class="icon-search"></em></span>
                    <mat-hint><span class="note">*</span> Enter at least 4 characters to search</mat-hint>
                </mat-form-field>
            </div>
            <div class="catalogWrapper">
                <div class="left">
                    <div class="catalogmenu" data-aos="fade-right" data-aos-duration="1200">
                        <ul class="menus" *ngFor="let value of tabValues" [class.active] = "value.cid == this.fromTab">
                            <li>
                                <div class="menuMob" (click)="tabChange(value)">
                                    <div>{{value.name}}</div>
                                    <div class="forMobile">
                                      <em [ngClass]="value.cid == fromTab ? 'icon-UpArrow' : 'icon-DownArrow'"></em>
                                         <!-- <em *ngIf="value.cid == fromTab" class="icon-DownArrow"></em>
                                         <em *ngIf="!value.cid == fromTab" class="icon-UpArrow"></em> -->
                                     </div>
                                </div>
                                <div class="forMobileContent" *ngIf="value.cid == fromTab">
                                    <div class="assessmentArea">
                                        <mat-tab-group mat-align-tabs="start" animationDuration="2000ms" [disableRipple]="true">
                                            <mat-tab>
                                                <ng-template mat-tab-label>
                                                    {{ tabName }}
                                                    <div class="badge">{{courseCount}}</div>
                                                </ng-template>
                                                <ng-template [ngTemplateOutlet]="mobskeleton" *ngIf="noDataSkeleton"></ng-template>
                                                <ng-template [ngTemplateOutlet]="catlog" *ngIf="!noDataSkeleton && areaCards.length > 0"></ng-template>
                                                <ng-template [ngTemplateOutlet]="nocard" *ngIf="!noDataSkeleton && areaCards.length == 0"></ng-template>
                                            </mat-tab>
                                        </mat-tab-group>
                                        <mat-paginator #paginator [length]="courseCount" [pageSize]="pageSize" (page)="paginate($event)"></mat-paginator>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="assessmentArea desktopContent">
                    <mat-tab-group mat-align-tabs="start" animationDuration="2000ms" [disableRipple]="true">
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{ tabName }}
                                <div class="badge">{{courseCount}}</div>
                            </ng-template>
                            <ng-template [ngTemplateOutlet]="skeleton" *ngIf="noDataSkeleton"></ng-template>
                            <ng-template [ngTemplateOutlet]="catlog" *ngIf="!noDataSkeleton && areaCards.length > 0"></ng-template>
                            <ng-template [ngTemplateOutlet]="nocard" *ngIf="!noDataSkeleton && areaCards.length == 0"></ng-template>
                          </mat-tab>
                    </mat-tab-group>
                    <mat-paginator #paginator [pageSize]="pageSize" (page)="paginate($event)"></mat-paginator>
                </div>
            </div>
        </div>
</section>

<ng-template #catlog>
    <div class="assessmentList">
         <div class="courseCards" *ngIf="areaCards.length">
             <ul>
               <li  *ngFor="let item of areaCards" data-aos="zoom-in" data-aos-duration="1200">
                <app-course-card  [courseData]="item" [isCatalog]="true" [isEnroll]="enroledCourse" [pageNum]="pageNumber"></app-course-card>
            </li>
             </ul>
         </div>
        <div style="clear: both;"></div>
    </div>
</ng-template>
<ng-template #skeleton>
        <div class="skeletonWrapper" *ngFor="let wrapperItem of [1,2]">
            <div class="items" *ngFor="let item of [1,2,3]">
                <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '50px', 'background-color': '#d8d9dd'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', 'margin-top': '10px', height: '20px', 'background-color': '#d8d9dd'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '20px', 'background-color': '#d8d9dd'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '20px', 'background-color': '#d8d9dd'}"></ngx-skeleton-loader>
            </div>
        </div>
</ng-template>
<ng-template #mobskeleton>
    <div class="skeletonWrapper">
        <div class="items">
            <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '50px', 'background-color': '#d8d9dd'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', 'margin-top': '10px', height: '20px', 'background-color': '#d8d9dd'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '20px', 'background-color': '#d8d9dd'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '20px', 'background-color': '#d8d9dd'}"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
<ng-template #nocard>
    <div class="noDataFound">
        <figure>
            <img src="https://lmsassetspremium.lntedutech.com/portalicons/record-not-found.webp" alt="" />
        </figure>
        <div class="heading3">Sorry! No result found</div>
    </div>
</ng-template>
