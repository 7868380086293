<!-- hiringPartnres -->
<section class="programCourse">
    <div class="container">
        <div class="coursesWrapper">
            <div class="listPart" *ngFor="let item of OurPartners?.courses">
                <div class="heading">{{item?.title}}</div>
                <ul class="division">
                    <li *ngFor="let childItem of item?.innerArray">{{childItem}}</li>
                </ul>
            </div>
        </div>
    </div>
</section>
