import { Output, EventEmitter, Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { AppConfigService } from '../utils/app-config.service';

@Injectable({ providedIn: 'root'})

export class SocketService {
    socket: any;
    loginDetails: any;
    observer: any;
    @Output() change: EventEmitter<boolean> = new EventEmitter();
    secretKey = '(!@#Passcode!@#)';

    constructor(private appConfig: AppConfigService) {
        this.loginDetails = JSON.parse(this.appConfig.getLocalStorage('userDetails')) ;
    }

    Connectsocket(type): Observable<number> {
        this.socket = io(environment.socketio);
        Observable.create(observer => {
            this.observer = observer;
        });
        this.loginDetails = JSON.parse(this.appConfig.getLocalStorage('userDetails')) ;
        this.loginDetails.userId = CryptoJS.AES.decrypt( this.loginDetails.userId, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
        if (type.type === 'disconnect') {
            this.socket.emit('logout', this.loginDetails.userId);
        }
        if (type.type === 'connect') {
            if (this.loginDetails) {
                this.socket.emit('info', this.loginDetails.userId);
            }
        }
        return this.createObservable();
    }

    socketReceiver() {
        this.socket.on('coursePlayerStatus', (msg: any) => {
            console.log(msg, 'inside service');
            if (this.loginDetails.userId === msg.user_id) {

                this.changeTrigger({
                    eventId: 'coursePlayerStatus',
                    data: msg,
                });
                this.observer.next(msg);
                this.newMessageReceived(msg);
            }
        });
    }

    createObservable(): Observable<number> {
        return new Observable<number>(observer => {
            this.observer = observer;
        });
    }
    public changeTrigger(event) {
        this.change.emit(event);
    }

    newMessageReceived(data) {
        const observable = new Observable<any>(observer => {
            observer.next(data);
        });
        return observable;
    }

    closeSocket() {
        if (this.socket.connected) {
        this.socket.removeAllListeners('coursePlayerStatus');
        this.socket.off('coursePlayerStatus');
        this.socket.off('disconnect', this.Connectsocket);
        }
    }

    socketStatus() {
        console.log("Socket Connected = ",this.socket.connected)
    }
}
